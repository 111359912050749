import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 720.000000 585.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,585.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2716 4924 c-3 -8 -6 -37 -6 -62 0 -77 56 -160 170 -253 96 -78 219
-134 665 -304 314 -119 461 -219 523 -354 45 -98 33 -268 -26 -361 l-15 -24
-13 39 -12 40 4 -58 c4 -76 -1 -87 -41 -87 -118 0 -188 177 -122 311 15 31 27
58 27 61 0 5 -153 -13 -160 -19 -2 -2 8 -14 24 -26 27 -22 28 -25 21 -80 -6
-50 -10 -57 -29 -57 -33 0 -179 98 -172 115 9 24 -11 27 -75 11 -76 -20 -114
-20 -171 0 -37 12 -49 22 -58 47 -12 37 1 53 56 63 26 5 47 -2 103 -32 91 -50
106 -41 67 40 -31 64 -24 62 -168 44 -54 -6 -101 -9 -105 -5 -4 4 1 32 11 62
18 52 18 54 -1 87 -42 73 -165 118 -323 118 -108 0 -182 -10 -325 -45 -56 -14
-158 -29 -232 -34 -73 -5 -137 -13 -144 -16 -17 -11 10 -25 49 -25 92 0 163
-86 339 -408 81 -147 105 -262 55 -262 -21 0 -42 -18 -42 -37 0 -21 71 -16
157 12 48 16 122 29 193 35 63 6 159 15 212 21 88 9 101 8 128 -8 29 -16 37
-17 159 -1 94 12 147 24 191 44 71 32 82 26 78 -41 -3 -47 -4 -47 114 -21 42
9 95 16 118 16 45 0 47 -5 30 -75 -30 -131 90 -264 282 -311 145 -36 175 -10
311 265 134 271 145 300 159 402 26 193 -34 331 -194 451 -40 30 -84 61 -98
69 -14 8 -32 19 -40 26 -15 11 -221 116 -290 146 -83 37 -359 149 -505 204
-66 25 -133 51 -150 59 -130 58 -560 214 -605 219 -30 3 -69 8 -86 11 -23 3
-33 0 -38 -12z m107 -765 c65 -17 137 -95 137 -149 0 -47 -28 -79 -85 -95 -87
-26 -101 -20 -142 55 -19 36 -48 86 -64 113 -35 57 -37 84 -6 90 29 6 113 -2
160 -14z m175 -305 c53 -6 61 -10 67 -31 9 -37 79 -101 119 -108 46 -9 59 -25
47 -58 -5 -14 -14 -28 -20 -31 -6 -4 -8 -20 -5 -35 8 -38 -21 -57 -103 -66
-60 -7 -61 -6 -103 31 -37 34 -120 143 -120 159 0 3 -11 22 -25 43 -13 20 -26
48 -27 62 -2 20 2 25 27 31 42 9 73 10 143 3z m570 -262 c5 -36 -31 -62 -85
-61 -27 1 -39 3 -28 6 54 12 76 27 82 55 6 32 26 33 31 0z"/>
<path d="M5505 4650 c-2 -3 115 -163 261 -355 145 -193 281 -373 302 -402 111
-154 785 -1043 789 -1040 11 12 -171 319 -260 437 -55 74 -104 137 -107 140
-6 5 -341 447 -405 534 -22 30 -147 196 -278 369 -206 272 -242 316 -268 319
-16 3 -32 2 -34 -2z"/>
<path d="M1441 4483 c-17 -20 -64 -104 -104 -187 -181 -367 -223 -501 -215
-678 4 -81 10 -106 34 -154 58 -115 189 -231 354 -314 90 -46 353 -149 442
-174 29 -8 91 -27 138 -41 179 -55 497 -135 950 -241 129 -30 292 -68 361 -84
69 -17 140 -30 158 -30 33 0 33 0 27 38 -23 130 -87 254 -168 327 -119 107
-262 161 -743 281 -187 47 -389 103 -449 124 -269 97 -399 223 -429 413 -14
92 0 189 52 342 50 146 61 234 34 285 -40 78 -173 130 -332 130 -80 0 -80 0
-110 -37z"/>
<path d="M1852 4029 c-54 -164 -55 -297 -2 -379 37 -58 82 -98 89 -79 2 7 -2
17 -10 23 -11 9 -14 48 -15 176 -2 192 -8 259 -27 297 l-14 26 -21 -64z"/>
<path d="M4699 3598 c-31 -58 -54 -229 -29 -213 6 3 10 19 10 36 0 16 3 29 8
29 4 0 8 10 8 23 3 45 15 130 20 138 3 5 3 9 0 9 -3 0 -11 -10 -17 -22z"/>
<path d="M3880 3155 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M6500 3031 c-98 -11 -138 -60 -107 -132 20 -46 102 -128 152 -150 64
-30 153 -22 201 16 l29 23 -24 23 c-13 12 -30 37 -37 56 -10 22 -19 31 -27 26
-7 -4 -30 -10 -52 -13 -39 -7 -40 -7 -14 -17 30 -13 79 -68 79 -89 0 -23 -27
-28 -73 -15 -29 9 -59 31 -99 72 -57 59 -82 109 -76 151 4 33 79 38 119 9 39
-29 42 -26 16 14 -12 19 -24 34 -27 33 -3 0 -30 -4 -60 -7z"/>
<path d="M6248 2973 c-29 -5 -39 -23 -12 -23 20 0 68 -53 99 -109 14 -24 25
-46 25 -48 0 -4 -86 41 -221 116 -49 27 -59 29 -86 19 l-31 -12 31 -20 c41
-28 157 -200 157 -233 0 -21 3 -24 23 -19 12 3 30 6 40 6 9 0 17 5 17 10 0 6
-6 10 -14 10 -15 0 -151 168 -143 176 5 6 139 -64 234 -121 60 -36 93 -44 93
-22 0 7 -30 53 -68 102 -90 121 -103 141 -96 159 6 16 4 17 -48 9z"/>
<path d="M5800 2883 c-14 -3 -28 -8 -32 -11 -4 -4 2 -41 12 -82 11 -41 20 -88
20 -103 0 -28 0 -28 -72 29 -40 31 -87 70 -105 87 -27 25 -36 28 -65 21 -18
-4 -40 -7 -48 -6 -8 1 -3 -4 11 -13 42 -23 159 -219 159 -265 0 -19 4 -20 38
-14 20 4 39 9 42 11 4 4 -13 18 -31 25 -11 5 -110 161 -105 166 5 5 53 -31
160 -120 39 -32 73 -55 78 -50 4 4 -5 56 -21 115 -16 59 -25 107 -20 107 16 0
119 -146 125 -179 6 -31 7 -31 30 -16 13 8 36 15 51 15 l27 0 -24 17 c-14 9
-28 22 -32 29 -5 7 -39 54 -78 105 -39 52 -70 104 -70 116 0 23 -4 24 -50 16z"/>
<path d="M5252 2765 c-54 -24 -69 -102 -30 -158 68 -96 129 -137 215 -143 48
-4 65 -1 98 18 55 32 64 68 35 137 -53 121 -209 193 -318 146z m157 -50 c53
-49 114 -161 109 -197 -5 -36 -51 -46 -94 -23 -62 33 -154 167 -154 223 0 37
6 42 52 42 32 0 47 -8 87 -45z"/>
<path d="M5025 2719 c-112 -16 -145 -42 -145 -114 0 -69 66 -152 154 -194 54
-27 147 -28 196 -3 29 14 35 22 33 46 -5 64 -33 74 -33 12 0 -71 -105 -70
-178 2 -43 42 -87 112 -97 155 -11 42 17 77 62 77 35 0 103 -33 103 -51 0 -5
5 -9 11 -9 7 0 5 9 -5 23 -9 12 -21 32 -26 45 -5 12 -11 21 -12 21 -2 -1 -30
-5 -63 -10z"/>
<path d="M6090 2662 c0 -26 33 -54 54 -46 22 8 20 16 -7 42 -28 27 -47 28 -47
4z"/>
<path d="M4600 2620 c-8 -5 -32 -10 -52 -10 -40 0 -51 -14 -19 -23 21 -5 81
-82 81 -103 0 -7 -19 -18 -42 -24 -24 -6 -48 -13 -55 -15 -27 -9 -89 91 -78
126 8 23 5 23 -42 4 -19 -8 -50 -15 -67 -15 l-31 0 30 -18 c44 -28 158 -216
159 -264 1 -38 13 -47 33 -27 6 6 35 14 64 19 43 6 49 9 32 15 -25 8 -93 96
-93 120 0 15 57 35 102 35 24 0 32 -8 56 -57 16 -32 27 -66 24 -75 -4 -22 -4
-22 41 -3 20 8 51 15 69 15 l33 0 -31 19 c-27 17 -66 68 -147 198 -15 24 -27
54 -27 68 0 26 -14 32 -40 15z"/>
<path d="M4175 2540 c-105 -14 -146 -38 -165 -96 -17 -51 2 -105 60 -164 62
-64 117 -89 193 -90 58 0 157 32 157 52 0 5 -8 25 -17 46 l-17 37 -4 -35 c-5
-49 -27 -70 -74 -70 -51 0 -108 30 -147 77 -70 85 -92 177 -49 207 43 30 96
19 152 -33 16 -14 27 -21 25 -16 -12 32 -45 94 -49 93 -3 0 -32 -4 -65 -8z"/>
<path d="M3780 2449 c-58 -17 -84 -23 -140 -28 -8 -1 0 -8 19 -17 42 -18 46
-23 121 -161 54 -98 60 -115 50 -134 -11 -20 -11 -21 11 -15 13 5 61 14 106
22 111 19 137 30 128 52 -3 9 -9 29 -12 45 -7 33 -23 36 -23 5 0 -33 -11 -45
-50 -55 -48 -13 -71 -3 -95 43 -11 22 -24 42 -30 46 -29 21 49 40 90 23 19 -8
19 -7 4 11 -9 10 -20 29 -23 42 -9 27 -22 29 -29 3 -4 -17 -53 -35 -69 -25
-15 9 -58 99 -54 111 8 19 82 16 113 -4 l26 -17 -24 37 c-27 42 -31 43 -119
16z"/>
<path d="M3545 2404 c-49 -16 -189 -47 -221 -48 -30 -1 -30 -1 -5 -56 19 -44
20 -44 20 -14 1 17 7 36 14 41 17 14 53 22 65 14 11 -7 116 -195 137 -246 13
-29 12 -35 0 -45 -25 -21 -6 -31 27 -14 16 9 54 18 84 21 60 6 70 17 25 26
-24 5 -39 22 -80 92 -64 108 -104 186 -98 192 12 11 68 5 91 -10 36 -24 40
-21 17 12 -12 16 -21 34 -21 40 0 13 -3 13 -55 -5z"/>
<path d="M4881 2401 c-17 -11 -7 -43 20 -59 16 -10 24 -10 35 -2 26 22 -25 79
-55 61z"/>
<path d="M133 2343 c3 -16 20 -116 37 -223 18 -107 39 -210 47 -229 8 -18 62
-151 120 -295 57 -143 109 -267 115 -273 9 -12 128 -20 128 -9 0 2 -18 48 -41
102 -22 55 -81 198 -131 319 -50 121 -114 276 -143 345 -29 69 -67 162 -85
208 -33 80 -58 110 -47 55z"/>
<path d="M3210 2326 c-43 -12 -44 -13 -22 -24 19 -11 22 -21 22 -72 0 -36 -4
-60 -11 -60 -6 0 -37 21 -70 46 -46 35 -58 50 -54 65 8 23 0 23 -55 4 -23 -8
-53 -15 -66 -15 -31 0 -31 -16 -1 -24 25 -6 140 -81 200 -131 46 -38 78 -115
59 -145 -10 -17 -10 -20 5 -20 10 1 29 7 43 15 14 8 43 14 66 15 53 0 63 11
23 24 -25 9 -40 26 -67 76 -33 62 -34 68 -29 144 3 43 10 83 16 89 15 15 14
27 -1 26 -7 -1 -33 -6 -58 -13z"/>
<path d="M2845 2260 c-4 -6 -24 -10 -46 -10 -113 0 -164 -79 -99 -154 39 -44
85 -65 168 -78 76 -11 102 -29 102 -69 0 -64 -84 -63 -149 2 -22 22 -45 38
-51 34 -15 -9 -13 -19 11 -43 11 -12 25 -35 30 -51 10 -29 11 -29 72 -24 123
10 182 61 157 137 -16 48 -58 81 -122 95 -115 26 -139 35 -158 58 -29 37 -12
63 41 63 29 0 47 -7 65 -25 38 -38 60 -31 29 11 -8 10 -15 26 -15 35 0 19 -26
33 -35 19z"/>
<path d="M2352 2152 c-29 -9 -64 -27 -77 -40 -82 -79 -26 -229 110 -300 97
-50 232 -26 289 51 55 75 -4 214 -114 269 -73 36 -140 42 -208 20z m106 -23
c75 -37 149 -173 140 -259 -4 -45 -39 -66 -91 -56 -116 22 -231 291 -139 324
38 15 41 14 90 -9z"/>
<path d="M7145 2104 c-49 -14 -1045 -282 -1255 -338 -30 -8 -156 -42 -280 -75
-124 -34 -301 -82 -395 -107 -249 -66 -419 -115 -425 -121 -3 -3 65 -3 150 0
141 5 168 9 305 47 472 129 973 266 1065 291 86 23 466 127 615 168 55 16 139
39 188 51 l87 23 0 39 c0 21 -1 38 -2 37 -2 0 -25 -7 -53 -15z"/>
<path d="M1960 2058 c-14 -6 -50 -13 -80 -17 l-55 -7 33 -17 c26 -14 44 -40
85 -125 64 -131 81 -181 65 -199 -17 -21 -5 -24 38 -9 21 8 75 19 118 26 121
18 128 22 121 67 -11 65 -17 71 -30 29 -10 -34 -18 -42 -54 -54 -66 -22 -79
-10 -143 119 -67 139 -72 153 -58 179 12 23 1 26 -40 8z"/>
<path d="M1605 1985 c-22 -7 -62 -16 -90 -20 l-50 -7 31 -13 c35 -15 54 -44
111 -175 52 -119 60 -149 42 -161 -28 -17 3 -22 36 -6 20 9 50 17 68 17 44 0
167 27 182 40 10 8 10 18 2 43 -5 18 -12 39 -14 47 -4 13 -5 13 -13 0 -6 -8
-10 -25 -10 -36 0 -37 -100 -63 -134 -35 -6 6 -37 61 -67 123 -41 86 -54 123
-53 155 0 24 0 43 0 42 -1 0 -19 -7 -41 -14z"/>
<path d="M1250 1910 c-52 -16 -108 -30 -125 -30 l-30 0 30 -16 c16 -8 31 -16
32 -18 2 -2 26 -54 54 -117 61 -136 75 -185 58 -205 -18 -22 -5 -26 32 -11 19
8 77 21 129 30 52 8 108 20 123 26 26 10 28 14 22 44 -4 17 -12 41 -18 52 -10
18 -11 17 -18 -14 -10 -47 -30 -63 -87 -69 -43 -4 -51 -1 -68 21 -11 13 -26
44 -34 67 -12 36 -12 43 1 51 19 12 86 11 94 -1 3 -5 11 -10 17 -10 6 0 4 10
-5 23 -8 13 -17 34 -19 47 -3 26 -28 40 -28 16 0 -21 -39 -46 -71 -46 -23 0
-30 9 -54 61 -16 37 -23 65 -18 70 5 5 32 12 60 15 44 6 53 3 71 -17 18 -18
22 -19 22 -6 0 8 -4 19 -10 22 -5 3 -10 15 -10 26 0 27 -34 24 -150 -11z"/>
<path d="M3055 1821 c17 -16 32 -27 35 -24 5 5 -46 53 -57 53 -4 0 6 -13 22
-29z"/>
<path d="M805 1816 c-38 -14 -87 -25 -107 -26 -21 0 -38 -4 -38 -10 0 -5 8
-10 18 -10 26 0 45 -25 77 -100 15 -36 36 -84 46 -107 33 -74 40 -125 22 -145
-20 -22 -4 -24 35 -4 15 7 58 16 95 20 170 17 247 80 206 169 -15 33 -78 67
-123 67 -42 0 -44 5 -14 30 30 24 30 82 0 112 -34 34 -127 35 -217 4z m108
-26 c27 -21 42 -67 33 -97 -9 -27 -43 -45 -64 -34 -20 11 -67 126 -57 141 10
17 61 11 88 -10z m112 -183 c12 -13 25 -44 29 -68 6 -38 4 -46 -15 -61 -27
-23 -62 -23 -81 0 -24 28 -70 141 -62 153 4 7 26 9 57 6 38 -3 56 -11 72 -30z"/>
<path d="M620 1454 c0 -3 11 -22 25 -42 28 -42 34 -29 8 17 -16 27 -33 41 -33
25z"/>
<path d="M0 35 l0 -35 3600 0 3600 0 0 35 0 35 -3600 0 -3600 0 0 -35z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
